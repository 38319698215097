
.login-form {
    max-width: 600px;
    width: 450px;
     background-color: #ffffff;
     padding: 60px;
     border-radius: 12px;
    padding-bottom: 20px;
}
h3{
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    color: #00837d;
}
.logo{
    display: block;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 10px;
}
 .login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
    height: 52px;
    font-size: 18px;
    margin-top: 10px;
}
.login-form input{
    height: 46px;
}
.login-form-button[disabled]{
    background-color: #00837d;
    opacity: 0.5;
    color: #ffffff;
}
.login-form-button[disabled]:hover{
    background-color: #00837d;
    opacity: 0.5;
    color: #ffffff;
}
