.container {
    display: flex;
}
.buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.buttons button {
    margin-right: 0.5rem;
}
.buttons button:last-child{
    margin-right: 0rem;
}
